import React from 'react'
import { graphql } from 'gatsby' // 'Link' is defined but never used.

import Cta from '../components/cta' // Imported JSX component CTA must be in PascalCase
import Layout from '../components/layout'
import Seo from '../components/seo' // Imported JSX component SEO must be in PascalCase

const PageTemplate = ({ data, pageContext, location }) => {
  const post = data.markdownRemark
  // const { previous, next } = pageContext //'previous' is assigned a value but never used. //'next' is assigned a value but never used.

  return (
    <Layout location={location}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article>
        <header>
          <h1>
            {post.frontmatter.title}
          </h1>
        </header>
        <section dangerouslySetInnerHTML={{ __html: post.html }} />
        <footer>
          <Cta />
        </footer>
      </article>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
      }
    }
  }
`
